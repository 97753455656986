.links {
    color: #707070;
    font-family: 'Avenir Next', 'Arial', 'sans-serif';
    font-weight: bold;
}

Navbar {
    background-color: white;
}

@media only screen and (max-width: 600px) {
    .padding-left-small-0 {
        padding-left: 0px;
    }    
  }
