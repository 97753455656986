body {
    font-family: 'Avenir Next', 'Arial', 'sans-serif';
}

.graffiti-image {
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
    object-fit: cover;
    width: 100%;
    margin-top: 0px;
}

.cover {
    width: 95%;
    height: 97%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 5px;
    pointer-events: none;
}

.random-color {
    width: 100%;
    height: 100%;
    top: 0px;
}

.global-radius {
    border-radius: 15px;
}

@media (min-width: 1500px) {
    .container {
        max-width: 1500px;
    }
}

.color-redish {
    background-color: #e98570;
}

.color-purplish {
    background-color: #403b5c;
}

.color-blueish {
    background-color: #0084b7;
}

.color-blueish-grey {
    background-color: #868397;
}

.color-red-purple {
    background-color: #94556a;
}