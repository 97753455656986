.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hidden-content {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  background-color: #ccc;
}

.hidden-content-intense-blur {
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);
  -o-filter: blur(15px);
  -ms-filter: blur(15px);
  filter: blur(15px);
  background-color: #ccc;
}

.text-clamp-10 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.text-clamp-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.text-clamp-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

@media (max-width: 500px) {
  .card-columns {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
  }
}

@media (min-width: 500px) {
  .card-columns {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
  }
}

@media (min-width: 800px) {
  .card-columns {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
  }
}

@media (min-width: 1200px) {
  .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
