.title {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 16px;
    color: black;
}

.subtitle {
    font-weight: medium;
    font-size: small;
}

.spotDescription {
    top: 20px;
    left: 10px;
    right: 10px;
    margin-right: 10px;
    color: white;
}

.spot-overlay-buttons {
    top: 210px;
    left: 20px;
}

.grid-margin-padding {
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    margin-top: 5px;
    padding: 5px;
}

.spot-hashtags {
    top: 20px;
    left: 10px;
    right: 10px;
    margin-right: 10px;
    color: white;
    font-weight: bold;
    text-transform: capitalize;
}